import { Box, Typography } from "@mui/material";
import Navbar from "../essentials/navbar";
import Footer from "../essentials/footer";

const TosPage = () => {
  return (
    <Box
      sx={{
        backgroundImage: "url(/assets/background2.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Navbar />
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        color={"white"}
        minHeight={"100vh"}
      >
        <Typography
          fontFamily={"'Bai Jamjuree', sans-serif"}
          mt={"10rem"}
          fontWeight={"600"}
          fontSize={"32px"}
        >
          TERMS OF SERVICE
        </Typography>
        <Box
          display={"flex"}
          flexDirection={"column"}
          width={"60%"}
          mt={"1rem"}
          bgcolor={"#1a232c"}
          borderRadius={"0.5rem"}
        >
          GENERAL
          <ul>
            <li>
              We reserve the right to terminate any service at any time without
              possibility of refund. Hopefully we won't have to.
            </li>
            <li>
              We reserve the right to change our Terms of Service at any time.
              It is your responsibility to keep yourself updated with our Terms
              of Service.
            </li>
            <li>
              Don't use your service to do anything that would be deemed illegal
              in your country of residence.
            </li>
            <li>
              Don't use your service to disrupt or interfere with any other
              BitNode service. Additionally, don't use your service to disrupt
              or intefere with any other service on the internet.
            </li>
          </ul>
          MINECRAFT
          <ul>
            <li>
              By purchasing a Minecraft server from our website, you agree
              to&nbsp;
              <a href="https://account.mojang.com/documents/minecraft_eula">
                Mojang's End User License Agreement
              </a>
              .
            </li>
            <li>
              If your server is found to be in violation of&nbsp;
              <a href="https://account.mojang.com/documents/minecraft_eula">
                Mojang's EULA
              </a>
              , your server may be suspended until you have removed the
              violating content from your server.
            </li>
            <li>
              Services provided by us are not explicitly limited by bandwidth,
              CPU or disk space; however, we have some restrictions.
            </li>
            <li>
              Disk space is 'unlimited' for the purpose of storing material
              related to your service. 100 GB of user data is acceptable.
              Hundreds of copyrighted music, movies or games are not.
            </li>
            <li>
              If using the plugin Dynmap or bluemap, map files should not exceed 10GB.
            </li>
            <li>
              If your service uses an excessive amount of bandwidth or CPU for
              prolonged periods, we will send you warnings via email. If you
              don't fix the problem, we may suspend your service.
            </li>
            <li>
              Each client may only store a maximum of 1 backup of their service
              at one time. Any additional backups must be stored externally.
            </li>
            <li>
              World files above 50GB must not include pre-generated chunks.
              Chunks should be generated through gameplay.
            </li>
            <li>
              Pre-generation includes both generation algorithms (e.g. Vanilla
              world generation or world generation mods and plugins) as well as
              computer-generated worlds using software such as WorldPainter
              (e.g. Earth maps).
            </li>
            <li>
              Exceeding any terms of our Fair Use Policy may result in the
              infringing files being removed.
            </li>
            <li>
              Any world pre-generation must not exceed a radius of 20K. This is
              because world sizes grow exponentially as the radius grows.
            </li>
          </ul>
          SECURITY
          <ul>
            <li>
              Your account is your account. Don't share your account details.
            </li>
            <li>
              As we have rules preventing you from sharing your account, we
              assume that any use of your service to violate this terms of
              service was done by you, therefore it will be you and your service
              that will be penalized.
            </li>
            <li>
              Furthermore, don't use your service to exploit flaws in our
              software or hardware, or the software or hardware of other
              internet services.
            </li>
          </ul>
          PAYMENT
          <ul>
            <li>
              Services are not activated until the first payment is received.
            </li>
            <li>
              If you don't renew your service before it expires, you have a one
              (1) day grace period in which you can pay the invoice for overdue
              services without disruption or penalty. Beyond this period, your
              service will be suspended until it's paid for.
            </li>
            <li>
              After thirty (30) days, without payment, your service will be
              terminated, thus deleting your files from our servers.
            </li>
            <li>
              Any chargebacks or payment disputes will result in immediate
              account suspension. Please contact support if you have a problem,
              we are willing to help sort things out.
            </li>
            <li>Account credit is non-refundable and non-withdrawable.</li>
          </ul>
          REFUNDS & DISPUTES
          <ul>
            <li>
              All refund requests must be made via a ticket on our website.
            </li>
            <li>
              When requesting a refund, you must be logged into your client
              account.
              <ul>
                <li>
                  If you are not logged into your client account when submitting
                  the ticket we cannot accept it as a refund request.
                </li>
                <li>
                  If this occurs, our support team will ask you to login and
                  submit the request. We are willing to grant 48 hours from the
                  time of our request for you to login and request a refund.
                </li>
                <li>
                  When logging in and opening your new request, you will need to
                  reference the original ticket ID from the ticket you created
                  while not logged in (this is only required if your initial 72
                  hour period has passed).
                </li>
                <li>
                  Failure to provide the ticket ID will mean we are unable to
                  locate your original request - we will not grant any
                  additional time in this case.
                </li>
              </ul>
            </li>
            <li>
              The following services are not eligible for a refund: Dedicated
              Servers.
            </li>
            <li>
              A payment made for the renewal of a service cannot be refunded.
            </li>
            <li>
              If the client opens a dispute, we reserve the right to suspend any
              and all of the client's services.
            </li>
            <li>
              All refunds will be provided as credit or via the original payment
              method at our discretion.
            </li>
            <li>
              If the client has opened any disputes on any transaction, refunds
              will only be provided at our discretion, and not in accordance
              with our refund policy.
            </li>
            <li>
              When a refund is issued, any related services will be terminated.
            </li>
          </ul>
          72 HOUR REFUND POLICY (MONEY BACK GUARANTEE)
          <ul>
            <li>
              <ul>
                <li>
                  Subject to the conditions below, we can provide a refund
                  within the first 72 hours of ordering a new service - all we
                  ask is that you tell us why you wish to cancel your service
                  and receive a refund.
                </li>
                <li>
                  Each client is only eligible to claim our 72 Hour Refund
                  Policy once.
                </li>
                <li>
                  Refunds for services are available within the first 72 hours
                  of a monthly plan commencing - please contact support to
                  request a refund. Beyond this period, refunds are given solely
                  at our discretion.
                </li>
                <li>
                  All refund requests must be submitted via a cancellation
                  request on our website. The 72 hours is counted from the time
                  of order to the time the cancellation is submitted.
                </li>
                <li>
                  The client may be asked why they wish to receive a refund
                  before a refund is issued, in which our team may offer
                  assistance to resolve the issue the client is experiencing. If
                  there is no response from the client within 48 hours of any of
                  our messages, eligibility to claim the 72 hour refund will be
                  lost.
                </li>
                <li>
                  The client must provide a reason for the refund request,
                  however the reason provided will not affect eligibility.
                </li>
                <li>
                  The client will be asked for confirmation on their refund
                  request before a refund is issued. If the client fails to make
                  a confirmation within 48 hours of our request to do so,
                  eligibility to claim the 72 hour refund will be lost.
                </li>
                <li>
                  Our 72 hour refund policy only covers the first 72 hours after
                  a new plan commences. Payments made for the renewal of
                  services are not eligible for our 72 Hour Refund Policy.
                </li>
                <li>
                  Opening a dispute on any of your payments will void
                  eligibility for our 72 Hour Refund Policy.
                </li>
                <li>
                  Our 72 hour money back guarantee only covers a single
                  transaction per client account.
                </li>
                <li>
                  If the client retracts the refund request or states that they
                  do not confirm the refund, the request will become void. A new
                  refund request can be submitted after this point, but will be
                  treated as a new request and will only be eligible if 72 hours
                  from the client's order has not passed.
                </li>
                <li>
                  Any client found to be creating multiple accounts in order to
                  claim the 72 hour refund multiple times will be denied a
                  refund.
                </li>
                <li>
                  Some payment methods may not support refunds (as determined by
                  the payment gateway). In this case, the refund will be issued
                  as account credit instead.
                </li>
                <li>
                  In order to maintain eligibility to receive a refund, you must
                  respond to all messages from us (including automated
                  responses) within 48 hours. Not responding to one of our
                  replies within 48 hours will void your eligibility to receive
                  a refund (unless you are still within the first 72 hours of
                  ordering a new service).
                </li>
              </ul>
            </li>
          </ul>
          OVERPAYMENTS & ACCIDENTAL PAYMENTS
          <ul>
            <li>
              <ul>
                <li>
                  We understand that overpayments and accidental payments
                  sometimes occur and are willing to work with you to resolve
                  the issue.
                </li>
                <li>
                  A payment made by the user is not an "accidental" payment, and
                  a refund of this type is subject to the conditions under the
                  "Refunds &amp; Disputes" section of this page.
                </li>
                <li>
                  An "accidental" payment is a payment that was made manually by
                  the client that was not due. For example, if the client had
                  completed their payment multiple times. Payments of this type
                  will be referred to as an "overpayment" from here on.
                </li>
                <li>
                  An overpayment occurs when an invoice is paid multiple times -
                  when this occurs, credit with the value of the overpayment is
                  added to the client's account.
                </li>
                <li>
                  Refunds can be offered for overpayments under the following
                  conditions:
                  <ul>
                    <li>
                      The refund request is made within a reasonable timeframe
                      of the payment being sent. Payments via PayPal and other
                      payment processors restrict the time frame a refund can be
                      issued. For this reason, we ask that your request is made
                      within 7 days to give us plenty of time to issue the
                      refund. If the refund request is made beyond this point,
                      we cannot guarantee we will be able to issue it.
                    </li>
                    <li>
                      Our system automatically adds overpayments to your account
                      credit - if any of this credit is used before a refund is
                      issued, a refund cannot be issued.
                    </li>
                    <li>
                      If there are multiple overpayments, only payments made
                      within the past 30 days at the time the ticket is handled
                      can be issued. This is why we ask that you open a ticket
                      with the request as soon as possible.
                    </li>
                    <li>
                      The credit is unused and remains in the client's account.
                    </li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li>
                      When requesting a refund for an overpayment, please ensure
                      you let us know the PayPal email used to make the
                      overpayment, or the equivalent information for the payment
                      method used to locate the payment, as well as the date the
                      payment was completed.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          CANCELLATIONS
          <ul>
            <li>
              If you do not wish to continue your service, you will need to
              submit a cancellation for the service from the BitNode client
              area. You may contact our support if you require assistance with
              this.
            </li>
            <li>
              You may also wish to cancel any PayPal subscriptions you may have
              activated for your account. As BitNode does not have access to the
              client's PayPal account we cannot and will not be responsible for
              incidental payments made from using the automatic payment
              subscription service.
            </li>
          </ul>
          PRIVACY
          <ul>
            <li>
              For security purposes, our website stores access logs with details
              for every visitor, which includes information such as your browser
              details and your IP address.
            </li>
            <li>
              Our website uses cookies, small pieces of information stored on
              your computer, for our Client Area (Billing) and Pterodactyl
              Control Panel. You can disable and delete these in your browser,
              however they are necessary for certain features to work correctly.
            </li>
            <li>
              Any personal details provided through our billing system are held
              until the account is closed. These details are only available to
              BitNode administrators and support staff and are only used for the
              purpose of fulfilling orders. To request an account close, contact
              support.
            </li>
          </ul>
          VIOLATIONS
          <ul>
            <li>
              For certain violations of these Terms of Service, we may endeavour
              to provide notices or warnings via email or support ticket,
              however this is at our discretion.
            </li>
            <li>
              Generally, any violation of these terms may result in account
              suspension, termination or closure without refund.
            </li>
            <li>
              Any attempts to exploit any part of our system in any way may
              result in account suspension, termination or closure without
              refund.
            </li>
          </ul>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default TosPage;
