import { Box, Typography, Button } from "@mui/material";
import Navbar from "../essentials/navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrochip,
  faMemory,
  faHardDrive,
  faCloud,
  faDatabase,
  faShield,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../essentials/footer";

const PricingPage = () => {
  return (
    <Box
      sx={{
        backgroundImage: "url(/assets/background2.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Navbar />
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        color={"white"}
        minHeight={"100vh"}
      >
        <Typography
          fontFamily={"'Bai Jamjuree', sans-serif"}
          mt={"10rem"}
          fontWeight={"600"}
          fontSize={"32px"}
        >
          GAME SERVERS
        </Typography>
        <Typography fontFamily={"'Bai Jamjuree', sans-serif"} mt={"-0.5rem"}>
          All servers are priced at recommended specs.
        </Typography>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          width={"60%"}
          mt={"1rem"}
        >
          <Box
            bgcolor={"#1a232c"}
            color={"white"}
            sx={{
              width: "33%",
              borderRadius: "0.5rem",
            }}
            mb={"3rem"}
          >
            {/* MINECRAFT */}
            <Box
              sx={{
                backgroundImage: "url(/assets/minecraft.jpg)",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "50px",
                borderRadius: "0.5rem 0.5rem 0 0",
              }}
            />
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Typography
                fontFamily={"'Kanit', sans-serif"}
                color={"white"}
                fontSize={"36px"}
                fontWeight={"500"}
              >
                MINECRAFT
              </Typography>
              <Typography
                fontFamily={"'Bai Jamjuree', sans-serif"}
                color={"white"}
                mt={"-10px"}
              >
                STARTING AT €2
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faMicrochip} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                INTEL @ 4.60 GHz
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faMemory} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                DDR5
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faHardDrive} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                NVME SSD
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faCloud} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                1 BACKUP
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faDatabase} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                3 DATABASES
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faShield} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                DDOS PROTECTION
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faTicket} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                24/7/365 SUPPORT
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={"0.5rem"}
            >
              <Button
                sx={{
                  width: "30%",
                  marginTop: "0.5rem",
                  backgroundColor: "#2a3a48",
                  ":hover": { bgcolor: "rgba(42,58,72, 0.8)" },
                  marginBottom: "0.5rem",
                }}
                onClick={() =>
                  (window.location.href =
                    "https://bitnodehost.com/clients/index.php?rp=/store/test-a-server")
                }
              >
                <Typography
                  color={"white"}
                  fontFamily={"'Bai Jamjuree', sans-serif"}
                >
                  TEST
                </Typography>
              </Button>
              <Button
                sx={{
                  width: "63%",
                  marginTop: "0.5rem",
                  backgroundColor: "#2a3a48",
                  ":hover": { bgcolor: "rgba(42,58,72, 0.8)" },
                  marginBottom: "0.5rem",
                }}
                onClick={() =>
                  (window.location.href =
                    "https://bitnodehost.com/clients/index.php?rp=/store/minecraft")
                }
              >
                <Typography
                  color={"white"}
                  fontFamily={"'Bai Jamjuree', sans-serif"}
                >
                  ORDER NOW
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box
            bgcolor={"#1a232c"}
            color={"white"}
            sx={{
              width: "33%",
              borderRadius: "0.5rem",
            }}
            mb={"3rem"}
          >
            {/* RUST */}
            <Box
              sx={{
                backgroundImage: "url(/assets/rust.jpg)",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "50px",
                borderRadius: "0.5rem 0.5rem 0 0",
              }}
            />
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Typography
                fontFamily={"'Kanit', sans-serif"}
                color={"white"}
                fontSize={"36px"}
                fontWeight={"500"}
              >
                RUST
              </Typography>
              <Typography
                fontFamily={"'Bai Jamjuree', sans-serif"}
                color={"white"}
                mt={"-10px"}
              >
                STARTING AT €8
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faMicrochip} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                INTEL @ 4.60 GHz
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faMemory} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                DDR5
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faHardDrive} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                NVME SSD
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faCloud} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                1 BACKUP
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faDatabase} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                3 DATABASES
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faShield} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                DDOS PROTECTION
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faTicket} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                24/7/365 SUPPORT
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Button
                sx={{
                  width: "95%",
                  marginTop: "0.5rem",
                  backgroundColor: "#2a3a48",
                  ":hover": { bgcolor: "rgba(42,58,72, 0.8)" },
                  marginBottom: "0.5rem",
                }}
                onClick={() =>
                  (window.location.href =
                    "https://bitnodehost.com/clients/index.php?rp=/store/game-servers/rust-server")
                }
              >
                <Typography
                  color={"white"}
                  fontFamily={"'Bai Jamjuree', sans-serif"}
                >
                  ORDER NOW
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box
            bgcolor={"#1a232c"}
            color={"white"}
            sx={{
              width: "33%",
              borderRadius: "0.5rem",
            }}
            mb={"3rem"}
          >
            {/* ARK: SURVIVAL EVOLVED */}
            <Box
              sx={{
                backgroundImage: "url(/assets/ark.jpg)",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "50px",
                borderRadius: "0.5rem 0.5rem 0 0",
              }}
            />
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Typography
                fontFamily={"'Kanit', sans-serif"}
                color={"white"}
                fontSize={"36px"}
                fontWeight={"500"}
              >
                ARK: SURVIVAL
              </Typography>
              <Typography
                fontFamily={"'Bai Jamjuree', sans-serif"}
                color={"white"}
                mt={"-10px"}
              >
                STARTING AT €12
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faMicrochip} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                INTEL @ 4.60 GHz
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faMemory} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                DDR5
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faHardDrive} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                NVME SSD
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faCloud} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                1 BACKUP
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faDatabase} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                3 DATABASES
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faShield} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                DDOS PROTECTION
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faTicket} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                24/7/365 SUPPORT
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Button
                sx={{
                  width: "95%",
                  marginTop: "0.5rem",
                  backgroundColor: "#2a3a48",
                  ":hover": { bgcolor: "rgba(42,58,72, 0.8)" },
                  marginBottom: "0.5rem",
                }}
                onClick={() =>
                  (window.location.href =
                    "https://bitnodehost.com/clients/index.php?rp=/store/game-servers/ark-server")
                }
              >
                <Typography
                  color={"white"}
                  fontFamily={"'Bai Jamjuree', sans-serif"}
                >
                  ORDER NOW
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          width={"60%"}
        >
          <Box
            bgcolor={"#1a232c"}
            color={"white"}
            sx={{
              width: "33%",
              borderRadius: "0.5rem",
            }}
            mb={"3rem"}
          >
            {/* SATISFACTORY */}
            <Box
              sx={{
                backgroundImage: "url(/assets/satisfactory.jpg)",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "50px",
                borderRadius: "0.5rem 0.5rem 0 0",
              }}
            />
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Typography
                fontFamily={"'Kanit', sans-serif"}
                color={"white"}
                fontSize={"36px"}
                fontWeight={"500"}
              >
                SATISFACTORY
              </Typography>
              <Typography
                fontFamily={"'Bai Jamjuree', sans-serif"}
                color={"white"}
                mt={"-10px"}
              >
                STARTING AT €20
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faMicrochip} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                INTEL @ 4.60 GHz
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faMemory} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                DDR5
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faHardDrive} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                NVME SSD
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faCloud} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                1 BACKUP
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faDatabase} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                3 DATABASES
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faShield} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                DDOS PROTECTION
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faTicket} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                24/7/365 SUPPORT
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Button
                sx={{
                  width: "95%",
                  marginTop: "0.5rem",
                  backgroundColor: "#2a3a48",
                  ":hover": { bgcolor: "rgba(42,58,72, 0.8)" },
                  marginBottom: "0.5rem",
                }}
                onClick={() =>
                  (window.location.href =
                    "https://bitnodehost.com/clients/index.php?rp=/store/game-servers/satisfactory")
                }
              >
                <Typography
                  color={"white"}
                  fontFamily={"'Bai Jamjuree', sans-serif"}
                >
                  ORDER NOW
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box
            bgcolor={"#1a232c"}
            color={"white"}
            sx={{
              width: "33%",
              borderRadius: "0.5rem",
            }}
            mb={"3rem"}
          >
            {/* TERRARIA */}
            <Box
              sx={{
                backgroundImage: "url(/assets/terraria.png)",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "50px",
                borderRadius: "0.5rem 0.5rem 0 0",
              }}
            />
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Typography
                fontFamily={"'Kanit', sans-serif"}
                color={"white"}
                fontSize={"36px"}
                fontWeight={"500"}
              >
                TERRARIA
              </Typography>
              <Typography
                fontFamily={"'Bai Jamjuree', sans-serif"}
                color={"white"}
                mt={"-10px"}
              >
                STARTING AT €2
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faMicrochip} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                INTEL @ 4.60 GHz
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faMemory} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                DDR5
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faHardDrive} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                NVME SSD
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faCloud} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                1 BACKUP
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faDatabase} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                3 DATABASES
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faShield} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                DDOS PROTECTION
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faTicket} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                24/7/365 SUPPORT
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Button
                sx={{
                  width: "95%",
                  marginTop: "0.5rem",
                  backgroundColor: "#2a3a48",
                  ":hover": { bgcolor: "rgba(42,58,72, 0.8)" },
                  marginBottom: "0.5rem",
                }}
                onClick={() =>
                  (window.location.href =
                    "https://bitnodehost.com/clients/index.php?rp=/store/game-servers/terraria-server")
                }
              >
                <Typography
                  color={"white"}
                  fontFamily={"'Bai Jamjuree', sans-serif"}
                >
                  ORDER NOW
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box
            bgcolor={"#1a232c"}
            color={"white"}
            sx={{
              width: "33%",
              borderRadius: "0.5rem",
            }}
            mb={"3rem"}
          >
            {/* SONS OF THE FOREST */}
            <Box
              sx={{
                backgroundImage: "url(/assets/sonsoftheforest.webp)",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "50px",
                borderRadius: "0.5rem 0.5rem 0 0",
              }}
            />
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Typography
                fontFamily={"'Kanit', sans-serif"}
                color={"white"}
                fontSize={"36px"}
                fontWeight={"500"}
              >
                SONS OF THE FOREST
              </Typography>
              <Typography
                fontFamily={"'Bai Jamjuree', sans-serif"}
                color={"white"}
                mt={"-10px"}
              >
                STARTING AT €16
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faMicrochip} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                INTEL @ 4.60 GHz
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faMemory} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                DDR5
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faHardDrive} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                NVME SSD
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faCloud} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                1 BACKUP
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faDatabase} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                3 DATABASES
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faShield} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                DDOS PROTECTION
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faTicket} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                24/7/365 SUPPORT
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Button
                sx={{
                  width: "95%",
                  marginTop: "0.5rem",
                  backgroundColor: "#2a3a48",
                  ":hover": { bgcolor: "rgba(42,58,72, 0.8)" },
                  marginBottom: "0.5rem",
                }}
                onClick={() =>
                  (window.location.href =
                    "https://bitnodehost.com/clients/index.php?rp=/store/game-servers/sons-of-the-forest-server")
                }
              >
                <Typography
                  color={"white"}
                  fontFamily={"'Bai Jamjuree', sans-serif"}
                >
                  ORDER NOW
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default PricingPage;
