import { Box, Button, Typography } from "@mui/material";
import Navbar from "../essentials/navbar";
import Footer from "../essentials/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrochip,
  faMemory,
  faHardDrive,
  faCloud,
  faDatabase,
  faShield,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";

const HomePage = () => {
  return (
    <Box
      height={"58rem"}
      sx={{
        backgroundImage: "url(/assets/background.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "bottom",
      }}
    >
      <Navbar />
      <Box
        height={"100%"}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        color={"white"}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={"10rem"}
          alignItems={"center"}
        >
          <Box>
            <Typography
              fontFamily={"'Bai Jamjuree', sans-serif"}
              fontWeight={"400"}
              fontSize={"25px"}
            >
              Welcome to
            </Typography>
            <Typography
              fontFamily={"'Bai Jamjuree', sans-serif"}
              fontWeight={"600"}
              fontSize={"40px"}
            >
              Bitnode Hosting
            </Typography>
            <Typography mt={"-10px"}>
              Quality servers for a cheap price.
            </Typography>
          </Box>
          <Box>
            <img src="/assets/logo.png" width={"300px"} alt="logo" />
          </Box>
        </Box>
      </Box>
      {/* ABOUT US */}
      <Box
        bgcolor={"#10171e"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        textAlign={"center"}
      >
        <Typography
          fontFamily={"'Bai Jamjuree', sans-serif"}
          color={"white"}
          mt={"10rem"}
          fontSize={"36px"}
          fontWeight={"600"}
        >
          ABOUT US
        </Typography>
        <Typography
          fontFamily={"'Bai Jamjuree', sans-serif"}
          color={"white"}
          fontSize={"18px"}
          fontWeight={"400"}
          width={"75%"}
        >
          BitNode is a game server provider, launched with intentions of
          providing the highest quality services at the lowest possible price.
          We’re a business based in Sweden and have been providing our services
          since 1st november 2021. Our team is comprised of experienced and
          dedicated gamers and developers who’ve been working in the hosting
          industry for multiple years. BitNode aims to provide an amazing user
          experience and the best service possible.
        </Typography>
        <Typography
          fontFamily={"'Bai Jamjuree', sans-serif"}
          color={"white"}
          fontSize={"18px"}
          fontWeight={"400"}
          width={"75%"}
          mt={"1rem"}
          mb={"5rem"}
        >
          This is why we use some of the best hardware available and have our
          staff team spread worldwide in order to provide 24 hour support to all
          clients. With our 72-hour money back guarantee, you’ve got nothing to
          lose! So what are you waiting for?
        </Typography>
      </Box>
      <Box
        bgcolor={"#1d242b"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        textAlign={"center"}
      >
        <Typography
          fontFamily={"'Bai Jamjuree', sans-serif"}
          color={"white"}
          mt={"7rem"}
          fontSize={"36px"}
          fontWeight={"600"}
        >
          GAME SERVERS
        </Typography>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          width={"60%"}
        >
          <Box
            bgcolor={"#1a232c"}
            color={"white"}
            sx={{
              width: "33%",
              borderRadius: "0.5rem",
            }}
            mb={"3rem"}
          >
            <Box
              sx={{
                backgroundImage: "url(/assets/minecraft.jpg)",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "50px",
                borderRadius: "0.5rem 0.5rem 0 0",
              }}
            />
            <Typography
              fontFamily={"'Kanit', sans-serif"}
              color={"white"}
              fontSize={"36px"}
              fontWeight={"500"}
            >
              MINECRAFT
            </Typography>
            <Typography
              fontFamily={"'Bai Jamjuree', sans-serif"}
              color={"white"}
              mt={"-10px"}
            >
              STARTING AT €2
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faMicrochip} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                I9-13900
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faMemory} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                DDR5
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faHardDrive} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                NVME SSD
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faCloud} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                1 BACKUP
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faDatabase} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                3 DATABASES
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faShield} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                DDOS PROTECTION
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faTicket} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                24/7/365 SUPPORT
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={"0.5rem"}
            >
              <Button
                sx={{
                  width: "30%",
                  marginTop: "0.5rem",
                  backgroundColor: "#2a3a48",
                  ":hover": { bgcolor: "rgba(42,58,72, 0.8)" },
                  marginBottom: "0.5rem",
                }}
                onClick={() =>
                  (window.location.href =
                    "https://bitnodehost.com/clients/index.php?rp=/store/test-a-server")
                }
              >
                <Typography
                  color={"white"}
                  fontFamily={"'Bai Jamjuree', sans-serif"}
                >
                  TEST
                </Typography>
              </Button>
              <Button
                sx={{
                  width: "63%",
                  marginTop: "0.5rem",
                  backgroundColor: "#2a3a48",
                  ":hover": { bgcolor: "rgba(42,58,72, 0.8)" },
                  marginBottom: "0.5rem",
                }}
                onClick={() =>
                  (window.location.href =
                    "https://bitnodehost.com/clients/index.php?rp=/store/minecraft")
                }
              >
                <Typography
                  color={"white"}
                  fontFamily={"'Bai Jamjuree', sans-serif"}
                >
                  ORDER NOW
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box
            bgcolor={"#1a232c"}
            color={"white"}
            sx={{
              width: "33%",
              borderRadius: "0.5rem",
            }}
            mb={"3rem"}
          >
            <Box
              sx={{
                backgroundImage: "url(/assets/rust.jpg)",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "50px",
                borderRadius: "0.5rem 0.5rem 0 0",
              }}
            />
            <Typography
              fontFamily={"'Kanit', sans-serif"}
              color={"white"}
              fontSize={"36px"}
              fontWeight={"500"}
            >
              RUST
            </Typography>
            <Typography
              fontFamily={"'Bai Jamjuree', sans-serif"}
              color={"white"}
              mt={"-10px"}
            >
              STARTING AT €8
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faMicrochip} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                I9-13900
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faMemory} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                DDR5
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faHardDrive} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                NVME SSD
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faCloud} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                1 BACKUP
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faDatabase} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                3 DATABASES
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faShield} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                DDOS PROTECTION
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faTicket} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                24/7/365 SUPPORT
              </Typography>
            </Box>
            <Button
              sx={{
                width: "95%",
                marginTop: "0.5rem",
                backgroundColor: "#2a3a48",
                ":hover": { bgcolor: "rgba(42,58,72, 0.8)" },
                marginBottom: "0.5rem",
              }}
              onClick={() =>
                (window.location.href =
                  "https://bitnodehost.com/clients/index.php?rp=/store/game-servers/rust-server")
              }
            >
              <Typography
                color={"white"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                ORDER NOW
              </Typography>
            </Button>
          </Box>
          <Box
            bgcolor={"#1a232c"}
            color={"white"}
            sx={{
              width: "33%",
              borderRadius: "0.5rem",
            }}
            mb={"3rem"}
          >
            <Box
              sx={{
                backgroundImage: "url(/assets/ark.jpg)",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: "50px",
                borderRadius: "0.5rem 0.5rem 0 0",
              }}
            />
            <Typography
              fontFamily={"'Kanit', sans-serif"}
              color={"white"}
              fontSize={"36px"}
              fontWeight={"500"}
            >
              ARK: SURVIVAL
            </Typography>
            <Typography
              fontFamily={"'Bai Jamjuree', sans-serif"}
              color={"white"}
              mt={"-10px"}
            >
              STARTING AT €12
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faMicrochip} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                I9-13900
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faMemory} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                DDR5
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faHardDrive} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                NVME SSD
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faCloud} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                1 BACKUP
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faDatabase} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                3 DATABASES
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faShield} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                DDOS PROTECTION
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              ml={"1rem"}
            >
              <FontAwesomeIcon icon={faTicket} />
              <Typography
                ml={"0.5rem"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                24/7/365 SUPPORT
              </Typography>
            </Box>
            <Button
              sx={{
                width: "95%",
                marginTop: "0.5rem",
                backgroundColor: "#2a3a48",
                ":hover": { bgcolor: "rgba(42,58,72, 0.8)" },
                marginBottom: "0.5rem",
              }}
              onClick={() =>
                (window.location.href =
                  "https://bitnodehost.com/clients/index.php?rp=/store/game-servers/ark-server")
              }
            >
              <Typography
                color={"white"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                ORDER NOW
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default HomePage;
