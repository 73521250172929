import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import HomePage from "./scenes/homePage";
import PricingPage from "./scenes/pricingPage";
import Four0four from "./scenes/essentials/404";
import NewWebsite from "./scenes/blog/newwebsite";
import TosPage from "./scenes/tosPage";
import Maintenance from "./scenes/essentials/maintenance";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/blog/new-site" element={<NewWebsite />} />
          <Route path="/terms-of-service" element={<TosPage />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/404" element={<Four0four />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
