import { Box } from "@mui/material";
import Navbar from "../essentials/navbar";

const NewWebsite = () => {
  return (
    <Box
      height={"100vh"}
      sx={{
        backgroundImage: "url(/assets/background.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "bottom",
      }}
    >
      <Navbar />
    </Box>
  );
};

export default NewWebsite;
