import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <Box>
      {/* <Box bgcolor={"#eed202"} display={"flex"} justifyContent={"center"}>
        <Box display={"flex"} flexDirection={"row"}>
          <Typography fontFamily={"'Bai Jamjuree', sans-serif"}>
            WE'RE CURRENTLY CREATING A NEW WEBSITE,
          </Typography>
          <Typography
            fontFamily={"'Bai Jamjuree', sans-serif"}
            fontWeight={"500"}
            ml={"5px"}
            sx={{
              ":hover": { cursor: "pointer", textDecoration: "underline" },
            }}
            onClick={() => navigate("/blog/new-site")}
          >
            READ MORE HERE
          </Typography>
          <Typography fontFamily={"'Bai Jamjuree', sans-serif"}>.</Typography>
        </Box>
      </Box> */}
      <Box
        position={"fixed"}
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        bgcolor={"rgba(0,0,0, 0.2)"}
      >
        <Box
          display={"flex"}
          padding={"0.5rem 6%"}
          gap={"3rem"}
          alignItems={"center"}
          color={"white"}
        >
          <Box display={"flex"} gap={"2rem"}>
            <Typography
              fontFamily={"'Bai Jamjuree', sans-serif"}
              fontWeight={"500"}
              fontSize={"20px"}
              sx={{ ":hover": { cursor: "pointer" } }}
              onClick={() => navigate("/")}
            >
              HOME
            </Typography>
            <Typography
              fontFamily={"'Bai Jamjuree', sans-serif"}
              fontWeight={"500"}
              fontSize={"20px"}
              sx={{ ":hover": { cursor: "pointer" } }}
              onClick={() => navigate("/pricing")}
            >
              PRICING
            </Typography>
          </Box>
          <Box>
            <img src="/assets/logo.png" alt="waw" width={"50px"} />
          </Box>
          <Box display={"flex"} gap={"2rem"}>
            <Typography
              fontFamily={"'Bai Jamjuree', sans-serif"}
              fontWeight={"500"}
              fontSize={"20px"}
              sx={{ ":hover": { cursor: "pointer" } }}
              onClick={() => navigate("/404")}
            >
              DEDICATED
            </Typography>
            <Typography
              fontFamily={"'Bai Jamjuree', sans-serif"}
              fontWeight={"500"}
              fontSize={"20px"}
              sx={{ ":hover": { cursor: "pointer" } }}
              onClick={() => navigate("/404")}
            >
              OTHER
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;
