import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <Box bgcolor={"#10171e"}>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Box width={"30%"}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            p={"1rem"}
            color={"white"}
          >
            <img alt="logo" src="/assets/logo.png" width={"100rem"} />
            <Box>
              <Typography
                fontFamily={"'Bai Jamjuree', sans-serif"}
                fontWeight={"600"}
                fontSize={"26px"}
              >
                Bitnode Hosting
              </Typography>
              <Typography
                mt={"-10px"}
                fontFamily={"'Bai Jamjuree', sans-serif"}
              >
                Quality servers for a cheap price.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box width={"70%"}>
          <Typography
            color={"white"}
            sx={{ ":hover": { cursor: "pointer" } }}
            onClick={() => navigate("/terms-of-service")}
            mt={"2rem"}
          >
            TERMS OF SERVICE
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
