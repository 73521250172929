import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Four0four = () => {
  const navigate = useNavigate();

  return (
    <Box
      height={"100vh"}
      sx={{
        backgroundImage: "url(/assets/background2.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        color={"white"}
      >
        <Typography
          fontFamily={"'Bai Jamjuree', sans-serif"}
          fontWeight={"600"}
          fontSize={"48px"}
        >
          404
        </Typography>
        <Typography
          fontFamily={"'Bai Jamjuree', sans-serif"}
          fontWeight={"600"}
          fontSize={"28px"}
          mt={"-20px"}
        >
          NOT FOUND
        </Typography>
        <Typography
          fontFamily={"'Bai Jamjuree', sans-serif"}
          fontWeight={"600"}
          fontSize={"28px"}
          mt={"5rem"}
        >
          THIS PART OF THE SITE IS CURRENTLY NOT WORKING.
        </Typography>
        <Typography
          fontFamily={"'Bai Jamjuree', sans-serif"}
          fontWeight={"400"}
          fontSize={"16px"}
          mt={"-10px"}
        >
          IF YOU THINK THIS IS A BUG PLEASE REPORT TO AN ADMINISTRATOR.
        </Typography>
        <Button
          sx={{
            width: "30%",
            marginTop: "0.5rem",
            backgroundColor: "rgba(16,23,30,255)",
            ":hover": { bgcolor: "rgba(16,23,30,0.8)" },
            marginBottom: "0.5rem",
          }}
          onClick={() => navigate("/")}
        >
          <Typography color={"white"} fontFamily={"'Bai Jamjuree', sans-serif"}>
            GO HOME
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Four0four;
